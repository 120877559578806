import React from "react";
import "./StyleSheets/UnderDev.css";
import "./StyleSheets/NavPage.css"
import {MyFooter} from "./Footer.js";
import {typeWriterV2} from "./JavaScript/TypeWriter";
import dummy from './Images/Dummy-forEventCalls.png';

export function UnderConstruction(){
    document.title = "Under Development"
    return(
        <>
        <section>
            <img src={dummy} alt="const" onLoad={typeWriterV2}/>
          <div className="under">
              <h1>This Page is Under<br/>Development</h1>
              <p>thank you for being patient, I'm doing some work on the site and will be back shortly ;)</p>
          </div>
          <div className="codeBlock">
              <div className="type">
                  <code id="demo"/>
              </div>
          </div>
        </section>
        <MyFooter/>
        </>
    );
}