
var TxtType = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtType.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }

    setTimeout(function() {
        that.tick();
    }, delta);
};

export function typeText() {
    var elements = document.getElementsByClassName('typewrite');
    for (var i=0; i<elements.length; i++) {
        //console.log("typing");
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
    document.body.appendChild(css);
}
var navElements;

/*Add id in mobile*/
export function removeID() {
    //console.log("removed")
    if(window.innerWidth<=600){
        //console.log("mobile");
        document.querySelector("ul").setAttribute("id","navElements");
        navElements =document.getElementById("navElements");
        navElements.style.maxHeight="0px";
        navElements.style.opacity="0";
    }
    if(window.innerWidth>600){
        //console.log("desktop");
        document.querySelector("ul").removeAttribute("id");
        document.querySelector("ul").style.opacity="1";
        document.querySelector("ul").style.maxHeight="240px";
        document.querySelector("ul").style.transition="none";
    }
}
/*view drop down ul in mobile*/
export function toggleMenu(){
    navElements.style.transition="1s";
    //console.log("Toggled");
    if(navElements.style.maxHeight==="0px")
    {
        navElements.style.maxHeight="240px";
        navElements.style.opacity="1";
        document.querySelector("header").style.backgroundColor="black";
        document.querySelector("section").addEventListener("click",cancelMenu)
        document.querySelector("section").addEventListener("touchmove",cancelMenu,{passive: true})
    }else
    {
        navElements.style.maxHeight="0px";
        navElements.style.opacity="0";
        document.querySelector("header").style.backgroundColor="transparent";
    }
}
export function cancelMenu(){
    navElements.style.maxHeight="0px";
    navElements.style.opacity="0";
    document.querySelector("header").style.backgroundColor="transparent";
}

window.addEventListener("scroll",function (){
    const header = document.querySelector("header");
    header.classList.toggle("sticky",window.scrollY>0);
})
window.addEventListener("resize",removeID);
window.addEventListener("load",removeID);
//window.addEventListener("load",typeText);


var txt = ":: I'm learning react> npx create-react-app thomy-portfolio > cd thomy-portfolio > npm start";
var speed = 50;

export function typeWriterV2(){
    var i = 0;
    typethis();
    function typethis() {
        console.log("np");
        try{
            if (i < txt.length) {
                document.getElementById("demo").innerHTML += txt.charAt(i);
                if (txt.charAt(i+1)===">"){
                    document.getElementById("demo").innerHTML += "<br/>";
                }
                i++;
                setTimeout(typethis, speed);
            }
        }catch (err){
            return null;
        }
    }
}

export function hideNavBar(){
    if(document.getElementById("navElements")!=null){
        cancelMenu();
    }
}

