import {ContentBox, ContentParagaph, ListBox} from "./ContentBox";
import Aos from "aos";
import {MyFooter} from "./Footer";
import React,{useEffect} from "react";
import "aos/dist/aos.css";
import "./StyleSheets/ContentStyles.css";
/*Image imports*/
import frontEndSkill from "./Images/frontend-skill.jpg";
import backEndSkill from "./Images/backend-skill-v2.jpg";
import UISkill from "./Images/uiux-skill.jpg";
import photoSkill from "./Images/photo-skill.jpg";
import graphicSkill from "./Images/graphic-skill.jpg";

export function Skills() {
    useEffect(() => {
        Aos.init({duration:1000});
        },[]);
    document.title = "Thomy | Skills"

    const frontEnd = ["HTML", "CSS", "JavaScript","React.js","Bootstrap","Vue.js"];
    const ui =["Pencil and paper (sometimes my genius is almost frightening)",
        "Adobe Photoshop","Adobe XD",
        "Jetbrains PHPStorm","VS Code"];
    const photo =["Event photography", "Model shoots","Product shoots","Colour grading (in the presence of a colour accurate monitor)"];
    const backEnd =["Java","Python","Kotlin","R","Scala","MySQL","Oracle"];
    const frame =["Play Framework", "SpringBoot"];
    const ML =["NumPy", "SeaBorn","Scikit-learn","TensorFlow"];
    const graphic=["Magazine layouts","Certificates","Logo Design","Visiting Cards","Anything that can be done on Photoshop"];
    // eslint-disable-next-line no-unused-vars
    const camera=["Canon EOS 100D", "Canon EOS 77D", "Canon EOS 7D mk2", "Canon EOS M"];
    const postPro=["Adobe Photoshop", "Adobe Lightroom Classic","Adobe Premiere Pro", "Magix Video Pro X",]

    return (
        <>
            <section>
                {/*Web Design and UI UX*/}
                <div id="first" className="ContentBox" data-aos={"fade-up"}>
                    <div className="Main">
                        <div className="flyer" style={{textAlign: "right"}}>
                            <ContentBox
                                heading="Web Design"
                                subHeading="UI UX Engineering"/>
                        </div>
                        <div className="grid" style={{float: "left"}}>
                            <div className="floatBox image">
                                <img  className="img" src={UISkill} alt="Front End"/>
                            </div>
                            <div className="floatBox text">
                                <ContentParagaph heading="Web Design &" subhead="UI UX Engineering"
                                                 content="From the prototype sketch on a plain white
                                                    paper to the deployment of the website, a one man show actually,
                                                     this website was designed and created by myself, what else more do you expect? :)"/>
                                <ListBox listHeading="Prototyping and Development tools that I make use of,"
                                         listElements={ui}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*FrontEnd*/}
                <div className="ContentBox" data-aos={"fade-up"}>
                    <div className="Main">
                        <div className="flyer"  style={{textAlign: "left"}}>
                            <ContentBox
                                heading="FrontEnd"
                                subHeading=" Development"/>
                        </div>
                        <div className="grid" style={{float: "right"}}>
                            <div className="floatBox image">
                                <img  className="img" src={frontEndSkill} alt="Front End"/>
                            </div>
                            <div className="floatBox text">
                                <ContentParagaph heading="Frontend" subhead="Development"
                                    content="Started messing around with HTML when I was 12,
                            got clung on to CSS after some time, was introduced to JavaScript during
                            Advanced Levels and currently addicted to React.js"/>
                                <ListBox listHeading="Front end languages and libraries that I use,"
                                         listElements={frontEnd}/>
                            </div>
                        </div>
                    </div>
                </div>

                {/*BackEnd*/}
                <div className="ContentBox" data-aos={"fade-up"}>
                    <div className="Main">
                        <div className="flyer" style={{textAlign: "right"}}>
                            <ContentBox
                                heading="Backend"
                                subHeading=" Development"/>
                        </div>
                        <div className="grid" style={{float: "left"}}>
                            <div className="floatBox image">
                                <img  className="img" src={backEndSkill} alt="Front End"/>
                            </div>
                            <div className="floatBox text">
                                <ContentParagaph heading="BackEnd" subhead="Development"
                                                 content="Since I'm experienced at the FrontEnd and my scores
                                                 are good at the BackEnd, should I call myself a FullStack
                                                 Developer?   Brain.think( );"/>
                                <ListBox listHeading="BackEnd Languages that I'm familiar with,"
                                         listElements={backEnd}/>
                                <ListBox listHeading='Frameworks that I "kinda" know,'
                                         listElements={frame}/>
                                <ListBox listHeading="Machine Learning libraries that I use in projects,"
                                         listElements={ML}/>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Graphic Design*/}
                <div className="ContentBox" data-aos={"fade-up"}>
                    <div className="Main">
                        <div className="flyer" style={{textAlign: "left"}}>
                            <ContentBox
                                heading="Graphic"
                                subHeading="Designing"/>
                        </div>
                        <div className="grid" style={{float: "right"}}>
                            <div className="floatBox image">
                                <img  className="img" src={graphicSkill} alt="Front End"/>
                            </div>
                            <div className="floatBox text">
                                <ContentParagaph heading="Graphic" subhead="Designing"
                                                 content='A hobby turned into profession, was the go-to-guy for
                                                graphic designing related work at school, form "Machan podi edit ekak" to
                                                "Thomy, will you take up the post as the Editor of  the Union?" '/>
                                <ListBox listHeading="Creative work that I do,"
                                         listElements={graphic}/>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Photography*/}
                <div  id="last" className="ContentBox" data-aos={"fade-up"}>
                    <div className="Main">
                        <div className="flyer" style={{textAlign: "right"}}>
                            <ContentBox
                                heading="Photography"
                                subHeading="and post production"/>
                        </div>
                        <div className="grid" style={{float: "left"}}>
                            <div className="floatBox image">
                                <img  className="img" src={photoSkill} alt="Front End"/>
                            </div>
                            <div className="floatBox text" id="photography">
                                <ContentParagaph heading="Photography" subhead="and post production"
                                                 content='"Like father, like son"'/>
                                                 <br/>
                                <ContentParagaph content='Guess what? I learned photography on a Nikon FM10,
                                from the 35mm film era of photography, transitioned to DSLRs during mid 2008,
                                currently in the transition to Mirrorless'/>
                                <ListBox listHeading="Specialized in,"
                                         listElements={photo}/>
                                <ListBox listHeading="Post production tools that I'm familiar with,"
                                         listElements={postPro}/>
                                <ContentParagaph content='"Believe in the magic of the light and the shade"'/>
                                <div className="linkBox">
                                    <a href="https://www.flickr.com/thomydecroos" target="_blank" rel="noreferrer">Visit my Flickr Photostream</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <MyFooter/>
        </>
    );
}