
import "./StyleSheets/UnderDev.css";
import "./StyleSheets/NavPage.css"
import {MyFooter} from "./Footer.js";
import {useLocation} from "react-router-dom";

function redirect(){
    setTimeout(function(){
        window.location.href="/";
    },7000)
}

export function NotFoundPage(){
    window.addEventListener("load",redirect);
    document.title = "Page Not Found"
    return(
        <>
            <section>
                <div className="under">
                    <h1>Aww, don't cry <br/> 404 it is. </h1>
                    <p>If you notice this notice you will notice that this notice is not worth noticing</p><br/>
                    <p> the page cannot be found</p>
                    <p>No match for <code>{useLocation().pathname}</code></p>
                    <br/><br/><br/>
                    <p>You will be redirected to the main page</p>
                </div>
            </section>
            <MyFooter/>
        </>
    );
}