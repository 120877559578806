import linkedIn from "./Images/FooterLogos/linkedin.png";
import insta from "./Images/FooterLogos/instagram.png";
import flickr from "./Images/FooterLogos/flickr.png";
import './StyleSheets/Footer.css';

export function MyFooter(){
    return(
        <footer>
            <div className="footer-logo-set">
                <div className="footer-logo">
                    <a href="https://www.linkedin.com/in/thomydecroos" target="_blank" rel="noreferrer">
                        <img className="footer-images" src={linkedIn} alt={"linkedin"}/></a></div>
                <div className="footer-logo">
                    <a href="https://www.instagram.com/thomydecroos" target="_blank" rel="noreferrer">
                        <img className="footer-images" src={insta} alt={"instagram"}/></a></div>
                <div className="footer-logo">
                    <a href="https://www.flickr.com/thomydecroos" target="_blank" rel="noreferrer">
                        <img className="footer-images" src={flickr} alt={"flickr"}/></a></div>
            </div>
        </footer>
    );
}