import React from "react";

export function ContentBox(props){
    return(
        <>
            <div className="Content">
                <h1>{props.heading}</h1>
                <h3>{props.subHeading}</h3>
            </div>
        </>
    );
}

export function ContentParagaph(props){
    /*For content which needs separation*/
    return(
        <>
            <div className="Content">
                <h1>{props.heading}</h1>
                <h3>{props.subhead}</h3>
                <p>{props.content}</p>
            </div>
        </>
    );
}

export function ListBox(props){
    return(
        <>
            <div className="Content">
                <h6>{props.listHeading}</h6>
                <ul>
                    <LoopList elements={props.listElements}/>
                </ul>
            </div>
        </>
    );
}
function LoopList(props){
    return(
        <>
            {props.elements.map(name => (
                <li>{name}</li>
            ))}
        </>
    );
}