import {ContentBox, ContentParagaph, ListBox} from "./ContentBox";
import Aos from "aos";
import {MyFooter} from "./Footer";
import React,{useEffect} from "react";
import "aos/dist/aos.css";
import "./StyleSheets/ContentStyles.css";
import SchoolIMG from "./Images/school.jpg";
import UniIMG from "./Images/iit.jpg";

const AL =["Information Technology - A","Biology - S", "Physics - S", "English - A"];
const OL =["8A's and a B for Tamil"];
const Co =["Secretary & Editor of The Science Union (2018/2019)","Asst. Hardware Head of the IT Society","President of the Scrabble Club (2018)",
    "Recipient of the Cletus Gomez Educational Scholarship","Prefect (2016 & 2017)","Editor of the Board of Prefects"
            ,"Editor of the Tamil Union (2018)","Editor of the English Union (2018)"]
const CoIIT =["Active Member of the IEEE student branch of IIT","Member of the Entrepreneurship Society","Member of the Mozilla Club","Placed 10th in PyHack 2021"
,"Participated in MathsHack organized by UoW (Results not yet announced)","PS - Due to the pandemic there's nothing much here : ("];
export function Education(){
    useEffect(() => {
        Aos.init({duration:1000});
    },[]);
    document.title = "Thomy | Skills"
    return(
      <>
          <section className="Education">
              {/*IIT*/}
              <div className="ContentBox" data-aos={"fade-up"}>
                  <div id="first" className="Main">
                      <div id="uow" className="flyer" style={{textAlign: "right"}}>
                          <ContentBox
                              heading="Technology"
                              subHeading="University of Westminster"/>
                      </div>
                      <div className="grid" style={{float: "left"}}>
                          <div className="floatBox image">
                              <img  className="img" src={UniIMG} alt="Front End"/>
                          </div>
                          <div className="floatBox text">
                              <ContentParagaph heading="Informatics Institute of Technology" subhead="Undergraduate Level"
                                               content="Affiliated with the University of Westminster, UK"/>
                              <ContentParagaph content="Currently following a BSc(Hons) Computer Science Degree.
                              Completed Level 4 and Level 5 (est. year of completion - 2023)"/>
                              <ListBox listHeading="Co curricular activities and Achievements"
                                       listElements={CoIIT}/>
                          </div>
                      </div>
                  </div>
              </div>

              {/*St. Benedict's*/}
              <div className="ContentBox" data-aos={"fade-up"}>
                  <div id="last" className="Main">
                      <div id="sbc" className="flyer" style={{textAlign: "left"}}>
                          <ContentBox
                              heading="Benedictine"
                              subHeading="St.Benedict's College"/>
                      </div>
                      <div className="grid" style={{float: "right"}}>
                          <div className="floatBox image">
                              <img  className="img" src={SchoolIMG} alt="Front End"/>
                          </div>
                          <div className="floatBox text">
                              <ContentParagaph heading="St. Benedict's College" subhead="Advanced levels"
                                               content="Good old days, the place which made me who I am today"/>
                              <ListBox listHeading="Advanced Level examination results"
                                       listElements={AL}/>
                              <ListBox listHeading="Ordinary Level examination results"
                                       listElements={OL}/>
                              <ListBox listHeading="Co curricular activities and Achievements"
                                       listElements={Co}/>
                          </div>
                      </div>
                  </div>
              </div>
              <MyFooter />
          </section>
      </>
    );
}