import { createBrowserHistory } from 'history';
import {Router, Switch, Route, Link} from "react-router-dom";
import {toggleMenu, hideNavBar} from './JavaScript/TypeWriter';
import {Home} from './Home';
import {UnderConstruction} from "./UnderDev";
import {NotFoundPage} from "./NotFound";
import {Skills} from "./Skills";
import {Education} from "./Education";
import {Contact} from "./Contact";
import './StyleSheets/MainStyles.css';
import './StyleSheets/NavBar.css';
import navIcon from './Images/threelines.png';

/*App.js By Thomy De Croos*/
const history = createBrowserHistory();

export default function App() {

    return (
      <>
        <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
        <meta
            name=''
        />
        <Router history={history} >
          <div>
            <header>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="logo"><Link to="/" onClick={hideNavBar}>TG</Link></a>
              <img src={navIcon} className="nav-icon" onClick={toggleMenu} alt={"nav icon"}/>
              <ul>
                <li><Link to="/" onClick={()=>{hideNavBar();}} >Home</Link></li>
                <li><Link to="/education" onClick={hideNavBar} >Education</Link></li>
                <li><Link to="/experience" onClick={hideNavBar} >Experience</Link></li>
                <li><Link to="/skills" onClick={hideNavBar}>Skills</Link></li>
                <li><Link to="/contact" onClick={hideNavBar} >Contact</Link></li>
              </ul>
            </header>

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/education" component={Education}/>
              <Route path="/skills" component={Skills}/>
              <Route path="/experience" component={Experience}/>
              <Route path="/contact" component={Contact}/>
              <Route exact path={"/"} component={Home} />
              <Route path="*" component={NotFoundPage}/>
              <Route path="" component={NotFoundPage}/>
            </Switch>
          </div>
        </Router>
      </>

  );
}

function Experience() {
  return(
      <UnderConstruction />
  );
}