import me from "./Images/hero-image-v3.png";
import "./StyleSheets/HomePage.css";
import {MyFooter} from "./Footer.js";
import {typeText, removeID} from './JavaScript/TypeWriter';

/*function to add overflow hidden during homepage load*/
function onload(){
    document.getElementById("overflow").style.overflow="hidden";
}
/*function to add overflow hidden during homepage unload*/
// eslint-disable-next-line no-unused-vars
function unload(){
    document.getElementById("overflow").style.overflow="auto";
}
export function Home(){
    document.title = "Thomy De Croos"
    /*Home.js by Thomy De Croos*/
    return (
        <>
            <section onLoad={removeID}>
                <div className="image">
                    <div className="hero-banner">
                        <p>Hi, I'm</p>
                        <h1>Thomy De Croos</h1>
                        <p   className="typewrite" data-period="2000"
                           data-type='[
                        "and I code",
                        "I am a Creator",
                        "I do backend",
                        "and I love design",
                        "I do frontend"
                        ]'>
                            <span className="wrap"/>
                        </p>
                    </div>
                </div>
                <div className="skills" id="overflow" onLoad={onload}>
                    <div className="image">
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img className="hero" src={me} alt={"my image"} onLoad={typeText}/>
                    </div>
                    <div className="skill-individual" id="skill1"><p>computer science</p></div>
                    <div className="skill-individual" id="skill2"><p>web designer</p></div>
                    <div className="skill-individual" id="skill3"><p>ui ux engineer</p></div>
                    <div className="skill-individual" id="skill4"><p>graphic designer</p></div>
                    <div className="skill-individual" id="skill5"><p>photographer</p></div>
                    <div className="skill-individual" id="skill6"><p>tech enthusiast</p></div>
                </div>
            </section>
            <MyFooter />
        </>
    );
}
