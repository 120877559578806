import linkedIn from "./Images/FooterLogos/linkedin.png";
import insta from "./Images/FooterLogos/instagram.png";
import flickr from "./Images/FooterLogos/flickr.png";
import location from "./Images/FooterLogos/location.png";
import mobile from "./Images/FooterLogos/mobile.png";
import email from "./Images/FooterLogos/mail.png";
import "./StyleSheets/ContactStyles.css"

export function Contact(){
    return(
        <>
            <section>
                <div id="first" className="contactBox">
                    <div className="divItem headingBox">
                        <h1>Get in touch</h1>
                    </div>
                    <div className="divItem cont">
                        <MediaBox heading2="E-mail" image={email} info="thomydecroos@gmail.com"
                                  imgalt="email" link={"mailto:thomydecroos@gmail.com"}/>
                    </div>
                    <div className="divItem cont">
                        <MediaBox heading2="Mobile" image={mobile} info="+94 711 440000"
                                  imgalt="mobile" link={"tel:+94711440000"}/>
                    </div>
                    <div className="divItem cont third">
                        <MediaBox heading2="Location" image={location} info="34/9, Elie House Road, Colombo-15"
                                  imgalt="location" link={"https://goo.gl/maps/ZTwFs4cNnoLW7U3D7"}/>
                    </div>
                    <div className="divItem headingBox">
                        <h1>Social</h1>
                    </div>
                    <div className="divItem cont">
                        <MediaBox heading2="Instagram" image={insta} info="@thomydecroos"
                                  imgalt="instagram" link={"https://www.instagram.com/thomydecroos"}/>
                    </div>
                    <div className="divItem cont">
                        <MediaBox heading2="LinkedIn" image={linkedIn} info="thomydecroos"
                                  imgalt="linkedin" link={"https://www.linkedin.com/in/thomydecroos"}/>
                    </div>
                    <div className="divItem cont third">
                        <MediaBox heading2="Flickr" image={flickr} info="thomydecroos"
                                  imgalt="flickr" link={"https://www.flickr.com/thomydecroos"}/>
                    </div>
                </div>
                <div className="backText">
                    <h1>Contact</h1>
                    <h3>Want to get in touch? I'd love to hear from you</h3>
                </div>
            </section>
        </>
    );
}
export function MediaBox(props){
    return(
      <div className="media">
          <a href={props.link} target="_blank" rel="noreferrer">
              <div className="headingContainer">
                  <h2>{props.heading2}</h2>
              </div>
              <div className="imageContainer">
                  <img className="contIMG" src={props.image} alt={props.imgalt}/>
              </div>
              <div className="infoContainer">
                  <p>{props.info}</p>
              </div>
          </a>
      </div>
    );
}